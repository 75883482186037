import React from 'react';
import { Col } from 'react-bootstrap';

const ProjectCard = ({ title, description, coverImg }) => {
    return (
        <Col sm={6} md={4}>
            <div className='proj-imgbx'>
                <img src={`/img/${coverImg}`} alt="cardImage" />
                <img src="../assets/img/coverImg2.png" alt="" />
                <div className='proj-txtx'>
                    <h4>{title}</h4>
                    <span>{description}</span>
                </div>
            </div>
        </Col>
    );
}

export default ProjectCard;
