import React from 'react';
import { Col, Container, Row, Nav, Tab } from 'react-bootstrap';
import ProjectCard from './ProjectCard';
import colorSharp2 from "../assets/img/color-sharp2.png"

const Projects = () => {
    const projects = [
        {
            title: "Business Startup",
            description: "Design & Development",
            coverImg: "coverImg1.png"
        },
        {
            title: "Business Startup",
            description: "Design & Development",
            coverImg: 'coverImg2.png'
        },
        {
            title: "Business Startup",
            description: "Design & Development",
            coverImg: 'coverImg3.png'
        },
        {
            title: "Business Startup",
            description: "Design & Development",
            coverImg: 'coverImg4.jpg'
        },
        {
            title: "Business Startup",
            description: "Design & Development",
            coverImg: 'coverImg5.jpg'
        },
        {
            title: "Business Startup",
            description: "Design & Development",
            coverImg: 'coverImg6.jpg'
        }
    ];

    return (
        <section className='project' id='projects'>
            <Container>
                <Row>
                    <Col size={12}>
                        <h2>Projects</h2>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Voluptatem eum laudantium hic harum repellat nulla, dolores obcaecati inventore amet maiores?</p>
                        <Tab.Container id='projects-tabs' defaultActiveKey='second'>
                            <Nav className='navContainer' variant="pills">
                                <Nav.Item>
                                    <Nav.Link eventKey="first">Resume</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="second">Projects</Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="third" >
                                        Testimonials
                                    </Nav.Link>
                                </Nav.Item>
                            </Nav>
                            <Tab.Content>
                                <Tab.Pane eventKey="second">
                                    <Row>
                                        {
                                            projects.map((projects, index) => (
                                                <ProjectCard
                                                    key={index}
                                                    {...projects}
                                                />
                                            ))
                                        }
                                    </Row>
                                </Tab.Pane>
                                <Tab.Pane eventKey="first">
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum recusandae minus quasi sapiente facere quam vero tempore voluptas doloremque consequuntur ad pariatur nostrum praesentium error ducimus voluptatibus, ratione illo qui!
                                </Tab.Pane>
                                <Tab.Pane eventKey="third">
                                    Lorem ipsum dolor sit amet consectetur adipisicing elit. Eum recusandae minus quasi sapiente facere quam vero tempore voluptas doloremque consequuntur ad pariatur nostrum praesentium error ducimus voluptatibus, ratione illo qui!
                                </Tab.Pane>
                            </Tab.Content>
                        </Tab.Container>
                    </Col>
                </Row>
            </Container>
            <img className='background-image-right' src={colorSharp2} alt="background" />
        </section >
    );
}

export default Projects;
